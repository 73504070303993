var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("headbox", {
        attrs: { title: "业务说明", isbackPath: true, path: "person" }
      }),
      !_vm.loading
        ? _c("img", {
            attrs: {
              src: require("@/assets/images/business_description.png"),
              alt: ""
            }
          })
        : _vm._e(),
      _vm.loading
        ? _c(
            "center",
            { attrs: { id: "loadingbox" } },
            [_c("van-loading", { attrs: { size: "70px" } })],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }